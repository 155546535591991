import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { Profile } from '~/src/features/auth';
import { ClientDetail, ClientList, MilestoneDetails, ProjectDetails, ProjectList } from '~/src/features/clients';
import { EstimateDetail, EstimateList } from '~/src/features/estimates';
import { Modeling, Experimental, InlineTags, Canvas } from '~/src/features/experimental';

// These routes can only be accessed by logged in users

export const protectedRoutes = [
  {
    path: '/',
    // element: <Home />,
    element: <Navigate to="/estimates" />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
  {
    path: '/clients',
    element: <ClientList />,
  },
  {
    path: '/clients/:clientSlug',
    element: <ClientDetail />,
  },
  {
    path: '/projects',
    element: <ProjectList />,
  },
  {
    path: '/projects/:projectSlug',
    element: <ProjectDetails />,
  },
  {
    path: '/estimates',
    element: <EstimateList />,
  },
  {
    path: '/estimates/:estimateId',
    element: <EstimateDetail />,
  },
  {
    path: '/milestones/:milestoneId',
    element: <MilestoneDetails />,
  },
  {
    path: '/experimental',
    element: <Experimental />,
  },
  {
    path: '/experimental/modeling',
    element: <Modeling />,
  },
  {
    path: '/experimental/modeling/:canvasId',
    element: <Canvas />,
  },
  {
    path: '/experimental/inlineTags',
    element: <InlineTags />,
  },
];
