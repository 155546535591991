import { BaseModel } from '~/src/http';

export type Canvas = {
  id: string;
  name: string;
  data: string;
  client_id: string;
  project_id?: string;
};

export const CanvasModel = new BaseModel<Canvas>('/api/canvas/');
