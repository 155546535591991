import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Client } from '~/src/features/clients';
import { Button, Drawer, DrawerProps, Input } from '~/src/ui';
import { slugify } from '~/src/utils/slugify';
import { ProjectModel } from '../../api';
import { Project } from '../../types';
import './ProjectDrawer.scss';

export type ProjectDrawerProps = DrawerProps & {
  client?: Client;
  onCreateProject?: (project: Project) => void;
  onUpdateProject?: (project: Project) => void;
  project?: Project;
};

export type ProjectForm = {
  name: string;
  slug: string;
  slack_url?: string;
  drive_folder_url?: string;
  harvest_url?: string;
  staging_url?: string;
  production_url?: string;
  figma_url?: string;
  zeplin_url?: string;
  frontend_repo_url?: string;
  backend_repo_url?: string;
  flutter_repo_url?: string;
  android_repo_url?: string;
  ios_repo_url?: string;
};

export const ProjectDrawer = ({
  client,
  isOpen,
  onClose,
  onCreateProject,
  onUpdateProject,
  project,
  ...rest
}: ProjectDrawerProps) => {
  const { handleSubmit, register, reset, setValue, watch } = useForm<ProjectForm>();

  const watchName = watch('name');

  React.useEffect(() => {
    if (!project) {
      reset();
      return;
    }

    reset({
      name: project.name,
      slug: project.slug,
      slack_url: project.slack_url || undefined,
      drive_folder_url: project.drive_folder_url || undefined,
      harvest_url: project.harvest_url || undefined,
      staging_url: project.staging_url || undefined,
      production_url: project.production_url || undefined,
      figma_url: project.figma_url || undefined,
      zeplin_url: project.zeplin_url || undefined,
      frontend_repo_url: project.frontend_repo_url || undefined,
      backend_repo_url: project.backend_repo_url || undefined,
      flutter_repo_url: project.flutter_repo_url || undefined,
      android_repo_url: project.android_repo_url || undefined,
      ios_repo_url: project.ios_repo_url || undefined,
    });
  }, [isOpen]);

  React.useEffect(() => {
    if (watchName === undefined) {
      return;
    }

    setValue('slug', slugify(watchName));
  }, [watchName]);

  const createOrUpdate = handleSubmit((data) => {
    if (!project) {
      createProject(data);
    } else {
      updateProject(data);
    }
  });

  function createProject(data: ProjectForm) {
    if (!client) {
      return;
    }

    ProjectModel.create({
      client: client.id,
      ...data,
    }).then((res) => {
      if (onCreateProject) {
        onCreateProject(res.data);
      }
    });
  }

  function updateProject(data: ProjectForm) {
    if (!project) {
      return;
    }

    const { slug, ...rest } = data;

    ProjectModel.update(project.slug, {
      ...rest,
    }).then((res) => {
      if (onUpdateProject) {
        onUpdateProject(res.data);
      }
    });
  }

  function renderFormField(name: string, label: string) {
    return (
      <div className="ProjectDrawer__form__field">
        <label className="ProjectDrawer__form__field__label" htmlFor={name}>
          {label}
        </label>
        <Input
          className="ProjectDrawer__form__field__control"
          fluid
          id={name}
          placeholder={label}
          {...register(name as any)}
        />
      </div>
    );
  }

  function renderUrlInputs() {
    const external = {
      slack_url: 'Slack',
      drive_folder_url: 'Drive Folder',
      harvest_url: 'Harvest',
    };

    const liveSite = {
      staging_url: 'Staging',
      production_url: 'Production',
    };

    const design = {
      figma_url: 'Figma',
      zeplin_url: 'Zeplin',
    };

    const repo = {
      frontend_repo_url: 'Frontend',
      backend_repo_url: 'Backend',
      flutter_repo_url: 'Flutter',
      android_repo_url: 'Android',
      ios_repo_url: 'iOS',
    };

    return (
      <>
        <h4>External</h4>
        {Object.entries(external).map(([key, value]) => (
          <div className="ProjectDrawer__form__group" key={key}>
            {renderFormField(key, value)}
          </div>
        ))}
        <h4>Live Site</h4>
        {Object.entries(liveSite).map(([key, value]) => (
          <div className="ProjectDrawer__form__group" key={key}>
            {renderFormField(key, value)}
          </div>
        ))}
        <h4>Designs</h4>
        {Object.entries(design).map(([key, value]) => (
          <div className="ProjectDrawer__form__group" key={key}>
            {renderFormField(key, value)}
          </div>
        ))}
        <h4>Repositories</h4>
        {Object.entries(repo).map(([key, value]) => (
          <div className="ProjectDrawer__form__group" key={key}>
            {renderFormField(key, value)}
          </div>
        ))}
      </>
    );
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} {...rest}>
      <div className="ProjectDrawer">
        <h2>{project ? 'Update' : 'Create'} Project</h2>
        <form className="ProjectDrawer__form" onSubmit={createOrUpdate}>
          <div className="ProjectDrawer__form__group">{renderFormField('name', 'Name')}</div>
          {!project && <div className="ProjectDrawer__form__group">{renderFormField('slug', 'Slug')}</div>}
          {project && <>{renderUrlInputs()}</>}
          <div className="ProjectDrawer__form__actions">
            <Button onClick={onClose} variant="raised">
              Cancel
            </Button>
            <Button color="primary" type="submit" variant="raised">
              {project ? 'Save' : 'Create'}
            </Button>
          </div>
        </form>
      </div>
    </Drawer>
  );
};
