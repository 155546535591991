import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Drawer, DrawerProps, Input, Toggle } from '~/src/ui';
import { slugify } from '~/src/utils/slugify';
import { ClientModel } from '../../api';
import { Client } from '../../types';
import './ClientDrawer.scss';

export type ClientDrawerProps = DrawerProps & {
  client?: Client;
  onClientCreate?: (client: Client) => void;
  onClientUpdate?: (client: Client) => void;
};

export type ClientForm = {
  name: string;
  slug: string;
  is_internal: boolean;
};

export const ClientDrawer = ({ client, onClientCreate, onClientUpdate, onClose, ...rest }: ClientDrawerProps) => {
  const { handleSubmit, register, reset, setValue, watch } = useForm<ClientForm>();

  const watchName = watch('name');

  React.useEffect(() => {
    if (!client) {
      reset();
      return;
    }
    reset({
      name: client.name,
      slug: client.slug,
      is_internal: client.is_internal,
    });
  }, [client]);

  React.useEffect(() => {
    if (watchName === undefined) {
      return;
    }

    setValue('slug', slugify(watchName));
  }, [watchName]);

  const createOrUpdate = handleSubmit((data) => {
    if (!client) {
      createClient(data);
    } else {
      updateClient(data);
    }
  });

  const createClient = (data: ClientForm) => {
    ClientModel.create(data).then((newClient) => {
      reset();
      if (onClientCreate) {
        onClientCreate(newClient.data);
      }
    });
  };

  const updateClient = (data: ClientForm) => {
    if (!client) {
      return;
    }

    const { slug, ...rest } = data;

    ClientModel.update(client.slug, rest).then((updatedClient) => {
      reset();
      if (onClientUpdate) {
        onClientUpdate(updatedClient.data);
      }
    });
  };

  return (
    <Drawer onClose={onClose} {...rest}>
      <div className="ClientDrawer">
        <h3>{!client ? 'Create' : 'Update'} Client</h3>
        <form className="ClientDrawer__form" onSubmit={createOrUpdate}>
          <div className="ClientDrawer__form__group">
            <div className="ClientDrawer__form__field">
              <label className="ClientDrawer__form__label">Name</label>
              <Input fluid {...register('name')} />
            </div>
          </div>
          {!client && (
            <div className="ClientDrawer__form__group">
              <div className="ClientDrawer__form__field">
                <label className="ClientDrawer__form__label">Slug</label>
                <Input fluid {...register('slug')} />
              </div>
            </div>
          )}
          <div className="ClientDrawer__form__group">
            <div className="ClientDrawer__form__field">
              <Toggle label="Internal" labelPosition="right" {...register('is_internal')} />
            </div>
          </div>
          <div className="ClientDrawer__form__actions">
            <Button
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
              variant="raised"
            >
              Cancel
            </Button>
            <Button color="primary" type="submit" variant="raised">
              Save
            </Button>
          </div>
        </form>
      </div>
    </Drawer>
  );
};
