export type TestFlow = {
  id: string;
  milestone: string;
  name: string;
  created_at: string;
  updated_at: string;
  order: number;
  test_steps: TestStep[];
};

export type TestStep = {
  id: string;
  created_at: string;
  updated_at: string;
  test_flow: string;
  type: 'INTERACT' | 'VERIFY';
  description: string;
  order: number;
};
