import * as React from 'react';
import * as Rf from 'reactflow';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Button, Input, Select, Toggle } from '~/src/ui';
import { useStore } from '../../stores';
import 'reactflow/dist/style.css';
import './ModelNode.scss';

export const dataTypes = [
  'string',
  'string array',
  'uuid',
  'int',
  'int array',
  'float',
  'float array',
  'boolean',
  'boolean array',
  'date',
  'date array',
  'object',
  'object array',
  'json',
];

export enum RelationshipTypes {
  OneToOne = 'OneToOne',
  OneToMany = 'OneToMany',
  ManyToOne = 'ManyToOne',
  ManyToMany = 'ManyToMany',
}

export function invertRelationshipType(r: RelationshipTypes) {
  switch (r) {
    case RelationshipTypes.OneToMany:
      return RelationshipTypes.ManyToOne;
    case RelationshipTypes.ManyToOne:
      return RelationshipTypes.OneToMany;
    default:
      return r;
  }
}

export type Relation = {
  to: string | undefined;
  sourceOrTarget: 'source' | 'target';
  type: RelationshipTypes;
  required: boolean;
};

export type ModelNodeProps = {
  title: string;
  fields: { label: string; type: string; required: boolean }[];
  relations: Relation[];
};

export type ModelNodeType = Rf.Node & {
  data: ModelNodeProps;
};

export const ModelNode = (props: Rf.NodeProps<ModelNodeProps>) => {
  const updateNodeInternals = Rf.useUpdateNodeInternals();
  const connectionNodeId = Rf.useStore((state) => state.connectionNodeId);
  const isTarget = connectionNodeId && connectionNodeId !== props.id;
  const [editing, setEditing] = React.useState(false);

  const nodes = useStore((store) => store.nodes);
  const getNodes = useStore((store) => store.getNodes);
  const setNode = useStore((store) => store.setNode);
  const deleteNode = useStore((state) => state.deleteNode);

  const edges = useStore((store) => store.edges);
  const setEdge = useStore((store) => store.setEdge);

  const onDataChange = React.useCallback((newData: any) => {
    setNode(props.id, newData);
    updateNodeInternals(props.id);
  }, []);

  React.useEffect(() => {
    updateNodeInternals(props.id);
  }, [edges]);

  const getRelationEdge = (relation: Relation) => {
    return edges.find(
      (edge) =>
        (edge.source === props.id || edge.target === props.id) &&
        (edge.target === relation.to || edge.source === relation.to)
    );
  };

  const updateEdgeLabels = (edge: Rf.Edge | undefined) => {
    if (!edge) return;

    const nodes = getNodes();
    const sourceNode = nodes.find((node) => node.id === edge.source);
    const targetNode = nodes.find((node) => node.id === edge.target);
    const sourceRelation = sourceNode?.data.relations.find((r: Relation) => r.to === edge.target);
    const targetRelation = targetNode?.data.relations.find((r: Relation) => r.to === edge.source);
    const edgeData = {
      ...edge.data,
      startLabel: sourceRelation.type.match(/^one.+$/i)
        ? sourceRelation.required
          ? '1'
          : '0...1'
        : sourceRelation.required
        ? '1...*'
        : '0...*',
      endLabel: targetRelation.type.match(/^many.+$/i)
        ? targetRelation.required
          ? '1...*'
          : '0...*'
        : targetRelation.required
        ? '1'
        : '0...1',
    };

    edge && setEdge(edge.id, edgeData);
  };

  function renderField(field: { label: string; type: string; required: boolean }, index: number) {
    if (!editing) {
      return <p>{`${field.required ? '+' : '-'} ${field.label}: ${field.type}`}</p>;
    }

    return (
      <span>
        <Input
          value={field.label}
          onChange={(e) =>
            onDataChange({
              ...props.data,
              fields: props.data.fields.map((f, idx) => (idx === index ? { ...f, label: e.target.value } : f)),
            })
          }
        />
        <Select
          value={field.type}
          onChange={(e) =>
            onDataChange({
              ...props.data,
              fields: props.data.fields.map((f, idx) => (idx === index ? { ...f, type: e.target.value } : f)),
            })
          }
        >
          {dataTypes.map((dt) => (
            <option value={dt}>{dt}</option>
          ))}
        </Select>
        <Toggle
          label="Required"
          checked={field.required}
          onChange={(e) =>
            onDataChange({
              ...props.data,
              fields: props.data.fields.map((f, idx) => (idx === index ? { ...f, required: e.target.checked } : f)),
            })
          }
        />
        <Button
          onClick={() => onDataChange({ ...props.data, fields: props.data.fields.filter((f, idx) => idx !== index) })}
        >
          <TrashIcon style={{ width: '24px' }} />
        </Button>
      </span>
    );
  }

  function renderRelation(relation: Relation) {
    const targetNode = nodes.find((node) => node.id === relation.to);

    return (
      <div className="ModelNode__relations__handleContainer">
        <Rf.Handle
          className="ModelNode__relations__handleContainer__handle"
          type={relation.sourceOrTarget || 'source'}
          position={Rf.Position.Left}
          id={`${props.id}-relation-${relation?.to}`}
        />
        <span className="ModelNode__relations__handleContainer__label">
          {targetNode?.data.title === '' ? (
            <p style={{ fontStyle: 'italic' }}>Unnamed model</p>
          ) : !targetNode?.data.title ? (
            <p>Connect a model...</p>
          ) : (
            <p>{targetNode?.data.title}</p>
          )}
          {relation.to && editing && (
            <>
              <Select
                value={relation.type}
                onChange={(e) => {
                  targetNode &&
                    setNode(targetNode.id, {
                      ...targetNode.data,
                      relations: targetNode.data.relations.map((r: Relation) =>
                        r.to === props.id
                          ? { ...r, type: invertRelationshipType(e.target.value as RelationshipTypes) }
                          : r
                      ),
                    });
                  onDataChange({
                    ...props.data,
                    relations: props.data.relations.map((r) =>
                      r.to === relation.to ? { ...r, type: e.target.value as RelationshipTypes } : r
                    ),
                  });
                  updateEdgeLabels(getRelationEdge(relation));
                }}
              >
                {Object.keys(RelationshipTypes).map((rt) => (
                  <option value={rt}>{rt}</option>
                ))}
              </Select>
              <Toggle
                checked={relation.required}
                label="Required"
                onChange={() => {
                  targetNode &&
                    setNode(targetNode.id, {
                      ...targetNode.data,
                      relations: targetNode.data.relations.map((r: Relation) =>
                        r.to === props.id ? { ...r, required: !r.required } : r
                      ),
                    });
                  onDataChange({
                    ...props.data,
                    relations: props.data.relations.map((r: Relation) =>
                      r.to === relation.to ? { ...r, required: !r.required } : r
                    ),
                  });
                  updateEdgeLabels(getRelationEdge(relation));
                }}
              />
            </>
          )}
        </span>
      </div>
    );
  }

  return (
    <>
      <div className="ModelNode" style={{ backgroundColor: isTarget ? 'lightgray' : 'white' }}>
        <Rf.Handle
          type="target"
          position={Rf.Position.Left}
          id={`${props.id}-target`}
          className="ModelNode__mainHandle"
          isConnectable={!!isTarget}
        />
        <div className="ModelNode__header">
          {!editing ? (
            <a className="ModelNode__header__link" onClick={() => setEditing(true)}>
              edit
            </a>
          ) : (
            <a
              className="ModelNode__header__link"
              onClick={() => {
                setEditing(false);
                onDataChange({
                  ...props.data,
                  fields: props.data.fields.filter((f) => f.label),
                  relations: props.data.relations.filter((r) => r.to),
                });
              }}
            >
              done
            </a>
          )}
          <a className="ModelNode__header__link" onClick={() => deleteNode(props.id)}>
            delete
          </a>
        </div>
        <div className="ModelNode__title">
          {!editing ? (
            props.data.title ? (
              <p>{props.data.title}</p>
            ) : (
              <p style={{ fontStyle: 'italic' }}>Unnamed Model</p>
            )
          ) : (
            <Input value={props.data.title} onChange={(e) => onDataChange({ ...props.data, title: e.target.value })} />
          )}
        </div>
        <div className="ModelNode__divider"></div>
        <div className="ModelNode__fields">
          <p style={{ fontWeight: 'bold' }}>Attributes</p>
          {props.data.fields.length ? (
            props.data.fields.map((field: { label: string; type: string; required: boolean }, index: number) =>
              renderField(field, index)
            )
          ) : (
            <p className="ModelNode__fields__info">No attributes</p>
          )}
          {editing && (
            <a
              className="ModelNode__fields__addIcon"
              onClick={() => {
                !props.data.fields.find((f) => !f.label) &&
                  onDataChange({
                    ...props.data,
                    fields: [...props.data.fields, { label: '', type: 'string', required: false }],
                  });
              }}
            >
              <PlusIcon />
              <p>Add attribute</p>
            </a>
          )}
        </div>
        <div className="ModelNode__divider"></div>
        <div className="ModelNode__relations">
          <p className="ModelNode__relations__title">Relations</p>
          {props.data.relations.length ? (
            props.data.relations.map((relation) => renderRelation(relation))
          ) : (
            <p className="ModelNode__relations__info">No relations</p>
          )}
          {editing && (
            <a
              className="ModelNode__relations__addIcon"
              onClick={() => {
                if (!props.data.relations.find((r) => r.to === undefined)) {
                  onDataChange({
                    ...props.data,
                    relations: [
                      ...props.data.relations,
                      { to: undefined, sourceOrTarget: 'source', type: RelationshipTypes.OneToMany, required: true },
                    ],
                  });
                }
              }}
            >
              <PlusIcon />
              <p>Add relation</p>
            </a>
          )}
        </div>
      </div>
    </>
  );
};
