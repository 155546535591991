import * as React from 'react';
import * as Rf from 'reactflow';
import './LabeledEdge.scss';

function EdgeLabel({ transform, label }: { transform: string; label: string }) {
  return (
    <div style={{ transform }} className="LabeledEdge nodrag nopan">
      {label}
    </div>
  );
}

export const LabeledEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}: Rf.EdgeProps) => {
  const [edgePath] = Rf.getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <Rf.BaseEdge
        id={id}
        path={edgePath}
        style={{
          stroke: 'rgba(150, 150, 150)',
          strokeWidth: 2,
        }}
      />
      <Rf.EdgeLabelRenderer>
        {data.startLabel && (
          <EdgeLabel
            transform={`translate(-100%, -50%) translate(${sourceX}px,${sourceY}px)`}
            label={data.startLabel}
          />
        )}
        {data.endLabel && (
          <EdgeLabel transform={`translate(-100%, -50%) translate(${targetX}px,${targetY}px)`} label={data.endLabel} />
        )}
      </Rf.EdgeLabelRenderer>
    </>
  );
};
