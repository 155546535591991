import { Bars2Icon } from '@heroicons/react/24/outline';
import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useRemark } from 'react-remark';
import { Badge, Button, Textarea } from '~/src/ui';
import { useMilestoneState } from '../../contexts';
import { TestStep as TestStepType } from '../../types';
import './DraggableTestStep.scss';

type DraggableTestStepProps = {
  step: TestStepType;
  index: number;
};

export const DraggableTestStep = (props: DraggableTestStepProps) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [description, setDescription] = React.useState(props.step.description);
  const { updateTestStep, deleteTestStep } = useMilestoneState();
  const [reactContent, setMarkdownSource] = useRemark({
    rehypeReactOptions: {
      components: {
        a: (props: any) => <a target="_blank" onClick={(e) => e.stopPropagation()} {...props} />,
      },
    },
  });

  React.useEffect(() => {
    setMarkdownSource(props.step.description);
  }, [props.step.description]);

  return (
    <Draggable draggableId={props.step.id} index={props.index}>
      {(provided) => (
        <>
          <div
            className={`DraggableTestStep${props.step.type === 'VERIFY' ? ' TestStep--verify' : ''}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="DraggableTestStep__body">
              <span className="DraggableTestStep__body__text">
                <Bars2Icon className="DraggableTestStep__body__text__dragHandle" />
                <Badge
                  color={props.step.type === 'INTERACT' ? 'green' : 'yellow'}
                  onClick={() =>
                    updateTestStep(props.step.id, { type: props.step.type === 'INTERACT' ? 'VERIFY' : 'INTERACT' })
                  }
                >
                  {props.step.type}
                </Badge>
                {!isEditing ? (
                  <div
                    className="DraggableTestStep__body__text__md"
                    onClick={() => setIsEditing(true)}
                    style={{ cursor: 'text' }}
                  >
                    {reactContent}
                  </div>
                ) : (
                  <Textarea
                    autoFocus
                    fluid
                    className="DraggableTestStep__body__text__textarea"
                    rows={5}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        description && updateTestStep(props.step.id, { description });
                        setIsEditing(false);
                      }
                      if (e.key === 'Escape') {
                        setDescription(props.step.description);
                        setIsEditing(false);
                      }
                    }}
                    onBlur={() => {
                      description && updateTestStep(props.step.id, { description });
                      setIsEditing(false);
                    }}
                  />
                )}
              </span>
              {!isEditing && (
                <span className="DraggableTestStep__body__options">
                  <Button color="blue" onClick={() => setIsEditing(true)}>
                    Edit
                  </Button>
                  <Button color="red" onClick={() => deleteTestStep(props.step.id)}>
                    Delete
                  </Button>
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </Draggable>
  );
};
