import * as React from 'react';
import { Link } from 'react-router-dom';

export const Experimental = () => {
  return (
    <div>
      <h1>Proof of Concept</h1>
      <ul>
        <li>
          <Link to={'/experimental/modeling'}>DB Modeling</Link>
        </li>
        <li>
          <Link to={'/experimental/inlineTags'}>Inline Object Tags</Link>
        </li>
      </ul>
    </div>
  );
};
