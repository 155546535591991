import * as React from 'react';
import * as Rf from 'reactflow';
import { useNavigate, useParams } from 'react-router';
import { Button, useToast } from '~/src/ui';
import { CanvasModel } from '../../api';
import { useStore } from '../../stores';
import { ModelNode, LabeledEdge, ConnectionLine } from '../../components';
import 'reactflow/dist/style.css';
import './Canvas.scss';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

export const Canvas = () => {
  return (
    <Rf.ReactFlowProvider>
      <CanvasInternals />
    </Rf.ReactFlowProvider>
  );
};

const CanvasInternals = () => {
  const { canvasId } = useParams();
  const navigate = useNavigate();
  const { getViewport, setViewport } = Rf.useReactFlow();
  const { info } = useToast();

  const [viewportData, setViewportData] = React.useState<any>();
  const nodeTypes = React.useMemo(() => ({ model: ModelNode }), []);
  const edgeTypes = React.useMemo(() => ({ labeled: LabeledEdge }), []);

  const nodes = useStore((state) => state.nodes);
  const addNode = useStore((state) => state.addNode);
  const setNodes = useStore((state) => state.setNodes);
  const onNodesChange = useStore((state) => state.onNodesChange);
  const edges = useStore((state) => state.edges);
  const setEdges = useStore((state) => state.setEdges);
  const onEdgesChange = useStore((state) => state.onEdgesChange);
  const onConnect = useStore((state) => state.onConnect);

  React.useEffect(() => {
    const unloadWarning = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = 'Are you sure you want to leave? Any unsaved changes will be lost.';
    };
    window.addEventListener('beforeunload', unloadWarning);
    return () => window.removeEventListener('beforeunload', unloadWarning);
  }, []);

  React.useEffect(() => {
    if (canvasId) {
      CanvasModel.get(canvasId).then((res) => {
        if (res.data.data) {
          const data = JSON.parse(res.data.data);
          setNodes(data.nodes);
          setEdges(data.edges);
          setViewportData(data.viewport);
        }
      });
    }
  }, [canvasId]);

  React.useEffect(() => {
    setViewport(viewportData);
  }, [viewportData]);

  function saveData() {
    const data = { nodes, edges, viewport: getViewport() };
    CanvasModel.update(canvasId as string, { data: JSON.stringify(data) });
    info('Saved!');
  }

  return (
    <div className="Canvas">
      <Rf.ReactFlow
        nodes={nodes}
        nodeTypes={nodeTypes}
        edges={edges}
        edgeTypes={edgeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        connectionLineComponent={ConnectionLine}
      >
        <Rf.Background />
        <Rf.MiniMap className="Canvas__minimap" />
        <Rf.Controls className="Canvas__controls" />
      </Rf.ReactFlow>
      <div className="Canvas__overlay">
        <div className="Canvas__overlay__back">
          <ArrowLeftIcon onClick={() => navigate(-1)} />
        </div>
        <span className="Canvas__overlay__buttons">
          <Button variant="raised" onClick={() => addNode(getViewport())}>
            Add new model
          </Button>
          <Button variant="raised" color="primary" onClick={saveData}>
            Save
          </Button>
        </span>
      </div>
    </div>
  );
};
