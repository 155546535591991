import { User } from '~/src/features/auth';

export type Estimate = {
  id: string;
  created_at: string;
  updated_at: string;
  client_slug: string;
  project: string;
  project_slug: string;
  milestone: string | null;
  name: string;
  description: string;
  safe_modifier: string;
};

export type EstimateSection = {
  id: string;
  order: number;
  created_at: string;
  updated_at: string;
  name: string;
  estimate: string;
  items: EstimateItem[];
};

export type EstimateItem = {
  id: string;
  order: number;
  created_at: string;
  updated_at: string;
  description: string;
  notes: string | null;
  questions: string | null;
  hours_high: string;
  hours_low: string;
  section: string;
  comment_count: number;
};

export type EstimateItemComment = {
  id: string;
  created_at: string;
  updated_at: string;
  content: string;
  item: string;
  user: User;
};
