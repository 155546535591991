import * as React from 'react';
import { Button, Input, Select } from '~src/ui';
import { Client, ClientModel } from '~src/features/clients';
import { Canvas, CanvasModel } from '../../api';
import { useNavigate } from 'react-router';
import { TrashIcon } from '@heroicons/react/24/outline';
import './Modeling.scss';

export const Modeling = () => {
  const [canvases, setCanvases] = React.useState<Canvas[]>([]);
  const [newCanvas, setNewCanvas] = React.useState<{ name: string; client_id: string }>({ name: '', client_id: '' });
  const [clients, setClients] = React.useState<Client[]>([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    ClientModel.all().then((res) => setClients(res));
    CanvasModel.all().then((res) => setCanvases(res));
  }, []);

  function createCanvas() {
    if (!newCanvas.name || !newCanvas.client_id) return;
    CanvasModel.create({ name: newCanvas.name, data: '', client_id: newCanvas.client_id }).then((res) => {
      setNewCanvas({ name: '', client_id: '' });
      navigate(`/experimental/modeling/${res.data.id}`);
    });
  }

  function deleteCanvas(id: string) {
    CanvasModel.delete(id).then(() => setCanvases((p) => p.filter((c) => c.id !== id)));
  }

  return (
    <div className="Modeling">
      <p className="Modeling__title">Database Modeling</p>
      <p className="Modeling__subtitle">All Canvases</p>
      <ul className="Modeling__canvaslist">
        {canvases.map((canvas) => (
          <li key={canvas.id} className="Modeling__canvas">
            <a href={`/experimental/modeling/${canvas.id}`}>{canvas.name}</a>
            <Button variant="raised" size="sm" color="red" onClick={() => deleteCanvas(canvas.id)}>Delete
            </Button>
          </li>
        ))}
      </ul>
      <p className="Modeling__subtitle">Create Canvas</p>
      <div className="Modeling__createCanvas">
        <div className="Modeling__createCanvas__labeledInput">
          <p>Canvas Name</p>
          <Input
            placeholder="New canvas"
            value={newCanvas.name}
            onChange={(e) => setNewCanvas((p) => ({ ...p, name: e.target.value }))}
          />
        </div>
        <div className="Modeling__createCanvas__labeledInput">
          <p>Client</p>
          <Select
            value={newCanvas.client_id}
            onChange={(e) => setNewCanvas((p) => ({ ...p, client_id: e.target.value }))}
          >
            {[
              <option value=""></option>,
              ...clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              )),
            ]}
          </Select>
        </div>
        <Button onClick={createCanvas} variant="raised" color="primary">
          Create
        </Button>
      </div>
    </div>
  );
};
