import * as React from 'react';

type ConnectionLineProps = {
  fromX: number;
  fromY: number;
  fromPosition: string;
  toX: number;
  toY: number;
  toPosition: string;
  connectionLineType: string;
  connectionLineStyle?: React.CSSProperties | undefined;
};

export const ConnectionLine = (props: ConnectionLineProps) => {
  return (
    <g>
      <path
        fill="none"
        stroke="#222"
        strokeWidth={1.5}
        className="animated"
        d={`M${props.fromX},${props.fromY} C${props.fromX - 150},${props.fromY} ${props.fromX},${props.toY} ${
          props.toX
        },${props.toY}`}
      />
      <circle cx={props.toX} cy={props.toY} fill="#fff" r={3} stroke="#222" strokeWidth={1.5} />
    </g>
  );
};
