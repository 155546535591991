import { User } from '~/src/features/auth';

export type Client = {
  id: string;
  name: string;
  slug: string;
  created_at: string;
  updated_at: string;
  is_internal: boolean;
  users: User[];
};

export type ClientUser = {
  id: string;
  created_at: string;
  updated_at: string;
  is_project_manager: boolean;
  client: string;
  user: string;
};

export type Milestone = {
  id: string;
  project: string;
  name: string;
  created_at: string;
  updated_at: string;
  completed_at: string | null;
  due_date: string | null;
  order: number;
};

export type Project = {
  id: string;
  client: string;
  client_name: string;
  client_slug: string;
  statement_of_work: string | null;
  name: string;
  slug: string;
  created_at: string;
  updated_at: string;
  completed_at: string | null;
  slack_url: string | null;
  drive_folder_url: string | null;
  harvest_url: string | null;
  staging_url: string | null;
  production_url: string | null;
  figma_url: string | null;
  zeplin_url: string | null;
  frontend_repo_url: string | null;
  backend_repo_url: string | null;
  flutter_repo_url: string | null;
  android_repo_url: string | null;
  ios_repo_url: string | null;
};
